import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "about",
    meta: { title: "gool" },
    component: () => import("../views/login.vue"),
  },

  {
    path: "/login",
    name: "about",

    component: () => import("../views/login.vue"),
  },
  {
    path: "/Map",
    name: "about",
    meta: { title: "map" },
    component: () => import("../views/Map.vue"),
  },

  {
    path: "/home",
    meta: { title: "home" },
    component: () => import("../views/home.vue"),
  },
  {
    path: "/mine",
    meta: { title: "mine" },
    component: () => import("../views/Mine.vue"),
  },
  {
    path: "/log",
    meta: { title: "mine" },
    component: () => import("../views/log.vue"),
  },
  {
    path: "/change",
    meta: { title: "mine" },
    component: () => import("../views/changepwd.vue"),
  },
  {
    path: "/LogDetail",
    meta: { title: "mine" },
    component: () => import("../views/logDetail.vue"),
  },
  {
    path: "/cam",
    meta: { title: "mine" },
    component: () => import("../views/cam.vue"),
  },

  // {
  //   path: '*',
  //   redirect: '/state',
  //   hidden: true
  // }
];

const router = new VueRouter({
  routes
})

export default router
